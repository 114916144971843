<template>
  <section class="invoice-add-wrapper">
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.modal-xl
      variant="outline-primary"
    >
      เพิ่มสินค้า
    </b-button>

    <b-modal
      v-model="dialog1"
      id="modal-xl"
      ok-only
      ok-title="close"
      centered
      size="lg"
      title="Add Content"
    >
      <b-card-text>
        <b-row class="invoice-add">
          <b-col cols="12" xl="12" md="12">
            <b-form enctype="multipart/form-data" @submit.prevent>
              <b-card no-body class="invoice-preview-card">
                <b-card-body class="invoice-padding form-item-section">
                  <div ref="form" class="repeater-form">
                    <b-row ref="row" class="pb-2">
                      <b-form @submit.prevent>
                        <b-row>
                          <b-col cols="6">
                            <b-form-group label="ชื่อสินค้า">
                              <b-input-group class="input-group-merge">
                                <b-form-input v-model="name" type="text" />
                              </b-input-group>
                            </b-form-group>
                          </b-col>

                          <b-col cols="6">
                            <b-form-group label="ราคาเต็ม">
                              <b-input-group class="input-group-merge">
                                <b-form-input v-model="price" type="number" />
                              </b-input-group>
                            </b-form-group>
                          </b-col>
                          <b-col cols="6">
                            <b-form-group label="ส่วนลด">
                              <b-input-group class="input-group-merge">
                                <b-form-input
                                  v-model="discount"
                                  type="number"
                                />
                              </b-input-group>
                            </b-form-group>
                          </b-col>
                          <b-col cols="6">
                            <b-form-group label="ราคาขาย">
                              <b-input-group class="input-group-merge">
                                <b-form-input
                                  v-model="selling_price"
                                  type="number"
                                />
                              </b-input-group>
                            </b-form-group>
                          </b-col>

                          <b-col cols="12">
                            <b-img thumbnail fluid :src="image" />

                            <b-form-file
                              placeholder="อัพโหลดรูป"
                              ref="refInputEl"
                              type="file"
                              accept=".jpeg,.png,.jpg,GIF"
                              :hidden="true"
                              @change="onFileChange"
                            />
                          </b-col>
                          <b-col cols="12">
                            <b-form-group label="Content Detail">
                              <quill-editor
                                v-model="detail"
                                :options="snowOption"
                              />
                            </b-form-group>
                          </b-col>

                          <b-col>
                            <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              type="submit"
                              variant="primary"
                              class="mr-1"
                              @click="save()"
                            >
                              บันทึก
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-form>
                    </b-row>
                  </div>
                </b-card-body>
              </b-card>
            </b-form>
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BListGroupItem,
  BCardTitle,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BCardCode,
  BFormFile,
  BImg,
  BMedia,
  VBModal,
  BFormDatepicker,
  BCarousel,
  BCarouselSlide,
  BContainer,
  BFormText,
  BAlert,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";

import api from "@/api";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BCardCode,
    BFormFile,
    BListGroupItem,
    BCardTitle,
    BImg,
    BMedia,
    VBModal,
    BFormDatepicker,
    BCarousel,
    BCarouselSlide,
    BContainer,
    BFormText,
    BAlert,
    quillEditor,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      dialog1: null,
      snowOption: {
        theme: "snow",
      },
      name: null,
      detail: null,
      price: null,
      selling_price: null,
      discount: null,
      package: null,
      fileImage: null,
      image: null,
    };
  },
  watch: {},
  mounted() {},
  created() {},
  computed: {},
  methods: {
    onFileChange(e) {
      this.fileImage = e.target.files[0];
      console.log("fileImage", this.fileImage);
      const image = e.target.files[0];
      this.image = URL.createObjectURL(image);
    },
    refresh() {
      this.$emit("loaddata", "loaddata");
    },
    save() {
      let formData = new FormData();

      formData.append("file", this.fileImage);
      formData.append("name", this.name);
      formData.append("detail", this.detail);
      formData.append("price", this.price);
      formData.append("discount", this.discount);
      formData.append("selling_price", this.selling_price);
      api
        .post("products_add", formData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("response.data :>> ", response.data);
          this.refresh()
         this.dialog = false;
          this.$swal({
            title: "เพิ่มสินค้าสำเร็จ!",
            // text: "ทำรายการสำเร็จ!",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        })
        .catch((error) => {
          this.$swal({
            title: "Error!",
            text: " โปรดลองใหม่อีกครั้ง!",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
  },
};
</script>
